import React from 'react';
import ApiKeys from '../../components/ApiKeys';
import Layout from '../../templates/layout';
import { Container } from '@mui/system';

const NotFoundPage = () => (
  <Layout>
    <Container sx={{marginY:"20px"}}>
      <ApiKeys />
    </Container>
  </Layout>
);

export const Head = () => <title>API keys | Zapłatomat</title>;


export default NotFoundPage;
